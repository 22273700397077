<template>
    <!-- 多期投注数据 -->
    <div v-if="isTermly" class='detail all_white'>
        <div class="detail_head">
            <div class="lottery_info">
                <div class="lottery_info_left">
                    <img :src="lotteryKind.icon" alt="">
                    <div>
                        <p>{{lotteryKind.name}}<span>{{playTypeName}}</span></p>
                        <p>{{progressType}}</p>
                    </div>
                </div>
                <p :class="progressStatus == '进行中' ? 'green' : getColor()">{{progressStatus}}</p>
            </div>
            <ul class="lottery_money">
                <li class="lottery_money_item" v-for="(item, index) in lottteryMoney" :key="index">
                    <p class="lottery_money_item_label">{{item.label}}</p>
                    <p class="lottery_money_item_num" ><span :class="{'red': index == 1}">{{appendCent(item.value)}}</span>元</p>
                </li>
            </ul>
        </div>
        <van-collapse v-model="activeNames">
            <van-collapse-item 
                :title="'第'+item.issueName+'期'" 
                v-for="(item, index) in lotteryList" 
                :value="getLotteryStatus(item.state, item.bonusState, item.iswin, item.prizemoney)"
                :key="index" 
                :name="index">
                <div class="bet_number" v-if="item.winCode && item.winCode.length > 0">
                    <div class="bet_number_title">
                        开奖号码:
                    </div>
                    <div class="bet_number_ball">
                        <Ball 
                            v-for="(item, index) in item.winCode" 
                            :key="index" 
                            :isChoose="item.isChoosed"
                            :color="item.color"
                            :value="item.value"/>
                    </div>
                </div>
                <div class="bet_number">
                    <div class="bet_number_title">
                        投注号码:
                    </div>
                    <div class="bet_number_ball" v-for="(line, index) in item.lotteryCode" :key="index">
                        <Ball 
                            v-for="(item, itemIndex) in line" 
                            :key="index+''+itemIndex" 
                            :isChoose="item.isChoosed"
                            :color="item.color"
                            :value="item.value"/>
                    </div>
                </div>
                 <div class="detail_info_termly">
                    <p>方案金额：  {{appendCent(item.money/100 || 0)}}元</p>
                    <p>投注倍数：{{item.playtimes || 1}}倍</p>
                    <p>中奖金额：{{appendCent(item.prizemoney/100 || 0)}}元</p>
                    <p>投注时间： {{item.time || '暂无'}}</p>
                    <p>订单编号： {{item.orderId || '暂无'}}</p>
                </div>
            </van-collapse-item>
        </van-collapse>
        <p class="load_more" @click="termlyBonusDetail">{{isLoadAll ? '已加载全部' : '点击加载更多'}}</p>
    </div>
    <!-- 普通投注数据 -->
    <div v-else class='detail all_white'>
        <div class="detail_head">
            <div class="lottery_info">
                <div class="lottery_info_left">
                    <img :src="lotteryKind.icon" alt="">
                    <div>
                        <p>{{lotteryKind.name}}<span>{{playTypeName}}</span></p>
                        <p>第<span>{{issueName}}</span>期</p>
                    </div>
                </div>
                <p :class="isWin ? 'red' : getColor()">{{isFail ? '彩金已退还' : isOpen ? (isWin ? '恭喜中奖' : '未中奖')  : '未开奖'}}</p>
            </div>
            <ul class="lottery_money">
                <li class="lottery_money_item" v-for="(item, index) in lottteryMoney" :key="index">
                    <p class="lottery_money_item_label">{{item.label}}</p>
                    <p class="lottery_money_item_num" ><span :class="{'red': index == 1}">{{appendCent(item.value)}}</span>元</p>
                </li>
            </ul>
        </div>
        <p class="detail_title">投注内容</p>
        <div class="detail_info" :class="{'padding_bottom_25': showPullIcon}">
            <div class="bet_number" v-if="isOpen">
                <div class="bet_number_title">
                    开奖号码:
                </div>
                <div class="bet_number_ball">
                     <Ball 
                        v-for="(item, index) in winNumber" 
                        :key="index"
                        :color="item.color"
                        :isChoose="item.isChoosed"
                        :value="item.value"/>
                </div>
            </div>
            <div class="bet_number">
                <div class="bet_number_title">
                    投注号码:
                </div>
                <div :style="heigthChange()" class="ani">
                     <div class="bet_number_ball" v-for="(ballLine, index) in betBalls" :key="index">
                        <Ball v-for="(ball, ballIndex) in ballLine" :key="index+'*'+ballIndex" :isChoose="ball.isChoosed" :color="ball.color" :value="ball.value"/>
                    </div>
                    <!-- 下拉按钮 -->
                    <div v-if="showPullIcon" class="bet_number_pull" @click="downEvent">
                    </div>
                </div>
            </div>
        </div>
        <p class="detail_title">方案内容</p>
        <div class="detail_info">
            <p>方案金额： {{appendCent(schemeBetMoney)}}元</p>
            <p>投注倍数：{{infos[0] && infos[0].playTimes || 1}}倍</p>
            <p>投注时间： {{infos[0] && infos[0].anteTime || '暂无'}}</p>
            <p>方案编号： {{infos[0] && infos[0].orderId || '暂无'}}</p>

        </div>
        <p class="detail_title">温馨提示</p>
        <div class="detail_info">
            <p class="tip_text" v-for="(text, index) in tips" :key="index">
                {{index+1}}、{{text}}。
            </p>
        </div>
    </div>
</template>
<script>
import { computed, reactive, toRefs } from 'vue'
import { getQueryStr, formatTime, stardAndEndTime, appendCent } from '../../utils/util'
import { gameIDMap } from '../../common-data'
import Ball from '../../components/Ball.vue'
import { lotteryDetail, termlyLotteryDetail } from '../../utils/api'
import { Collapse, CollapseItem } from 'vant'
export default {
    name: '',
    components: { Ball, 'van-collapse': Collapse, 'van-collapse-item': CollapseItem},
    setup() {
        const state= reactive({
            isWin: true,
            betMoney: 5,
            winMoney: 10,
            schemeBetMoney: 0,
            betTime: '2021-10-12',
            schemeNo: '123456464',
            tips: [
                '单注中奖金额小于或等于1万元的，系统自动派奖至您的账户;单注中奖金额大于1万元的，须凭本人有效身份证件至福彩中心兑奖处兑奖。(兑奖地址:四川中路321号，工作日9：30-16：00)',
                '因故未能短信投注成功，我们将退款至您的账户'
            ],
            infos: [],
            winNumber: [], // 普通投注中奖号码
            lottteryMoney: [{label: '投注金额', value: 0}, {label: '中奖金额', value: 0.00}],
            betBalls: [], // 普通投注投注号码
            down: false,
            isOpen: false,
            playTypeName: '',
            noData: false,
            isFail: '',
            page: 1,
            pageSize: 10,
            lotteryList: [], // 
            isInit: false,
            progressStatus: '进行中', // 方案状态
            progressType: '', // 方案类型
            activeNames: ['1'],
            isLoadAll: false,
        })
        const isWin = computed(() => {
            const value = state.lottteryMoney[1].value > 0
            return value
        })
        const showPullIcon = computed(() => {
            let len = 0
            state.betBalls.forEach(ele => len += Math.ceil(ele.length/7))
            console.log(len)
            return len > 5
        })
        const gameId = parseInt(getQueryStr('gameId')) || 11
        const issueName = getQueryStr('issueName')
        const orderId = getQueryStr('orderId')
        const lotteryKind = gameIDMap.get(gameId)
        const isTermly = getQueryStr('termly') == 'true'
        // 获取中奖金额
        function getWinMoney(data) {
            let num = 0
            data.forEach(ele => {
                if (ele.isgrandprize) {
                    num += ele.levelmoney * ele.amount
                } else {
                    num += ele.money
                }
            })
            return num / 100 || 0
        }
        function getColor() {
            return state.isFail ? 'black'
                    : state.isOpen ? 'green' : 'black'
        }
        // 获取投注金额
        function getBetMoney(data) {
            let num = 0 
            if (data[0] && data[0].planType == 0) {
                num = data[0].tickets && data[0].tickets[0] && data[0].tickets[0].money
            } else {
                num = data[0] && data[0].betMoney
            }
            return num / 100 || 0
        }
        // 分解红球和篮球
        function splitBetNum(code = []) {
            const balls = code && code.split('#')
            const redBalls = balls && balls[0] ? balls[0].split(',') : []
            const blueBalls = balls && balls[1] ? balls[1].split(',') : []
            return {redBalls, blueBalls}
        }
        // 下拉事件
        function downEvent() {
            state.down = !state.down
        }
         // 球球上色
        function colorNumber(balls, color, comparison = []) {
            let _balls = []
            balls.forEach(ele => {
                _balls.push({value: ele, color: color, isChoosed: comparison.indexOf(ele) > -1})
            })
            return _balls
        }
        // 获取展示的中奖号码
        function getShowWinCode(wincode) {
            const {redBalls, blueBalls} = splitBetNum(wincode)

            const redBall = colorNumber(redBalls, 'red', redBalls),
                  blueBall = colorNumber(blueBalls, 'blue', blueBalls)

            const codes = [...redBall, ...blueBall]
            return codes
        }
        // 获取投注号码
        function getBetCode(tickets, winCode) {
            let tempCodes  = [], codes = []
            let winRed = winCode.redBalls || [], winBlue = winCode.blueBalls || []
            let balls = []// 展示的投注号码
            tickets.forEach(code => {
                    for(let i = 5; i > 0 ; i--) {
                        if (code[`anteCode${i}`])
                        tempCodes.push(code[`anteCode${i}`])
                    }
                })
            tempCodes.forEach(ele => {
                balls = []
                let {redBalls, blueBalls} = splitBetNum(ele)
                balls.push(...colorNumber(redBalls, 'red', winRed), ...colorNumber(blueBalls, 'blue', winBlue))
                codes.push(balls)
                
            })
            return codes
        }
        // 获取普通投注详情
        async function bonusList() {
            try {
                const params = {
                    gameId: gameId,
                    issueName: issueName,
                    orderId: orderId,
                }
                const result = await lotteryDetail(params)
                if (result.code == '0000') {
                    state.infos = result.bonusList
                    const info0 = state.infos[0]
                    const winCode = result.bonusList && result.bonusList[0].winCode
                    if (winCode) {
                        state.isOpen = TextTrackCue
                        state.lottteryMoney[1].value = getWinMoney(state.infos)
                        state.isWin =  state.lottteryMoney[1].value > 0
                        state.winNumber = getShowWinCode(winCode)
                    }
                    state.isFail = info0.status == -1
                    state.playTypeName = state.infos[0].playTypeName || state.infos[0].tickets[0] && state.infos[0].tickets[0].playTypeName
                    state.lottteryMoney[0].value = getBetMoney(state.infos)         
                    state.schemeBetMoney = state.infos[0].betMoney / 100 || 0
                    state.betBalls = getBetCode(state.infos[0].tickets, splitBetNum(winCode))

                }
            } catch(e) {
                console.error(e)
            }
        }
        function heigthChange() {
            return {
                maxHeight: `${state.down ? 1000 : 200}px`
            }
        }
        const {start, end} = stardAndEndTime([26, 'M'])

        // 初始化多期投注号码数据
        function initTermlyNumber(data) {
            // 开奖号码
            data.winCode = getShowWinCode(data.winningcode)
            data.lotteryCode = getBetCode(data.tickets, splitBetNum(data.winningcode))

        }
        // 获得多期投注详情
        async function termlyBonusDetail() { 
            if(state.isLoadAll) {
                return
            }
            try {
                
                const params = {
                    orderId,
                    gameId,
                    page: state.page,
                    pageSize: state.pageSize,
                    startTime: start,
                    endTime: end
                }
                const result = await termlyLotteryDetail(params)
                if (result.code == 0) {
                    if (!state.isInit) {
                        state.isInit = true 
                        state.lottteryMoney.forEach((ele, index) => {
                            ele.label = index == 0 ? '累计投注金额' : '累计中奖金额'
                            ele.value = index == 0 ? result.totalAnteMoney/100 || 0 : result.totalPrizeMoney/100 || 0
                        })
                        const status = result.status
                        state.progressStatus = status == 0 ? '进行中'
                            : status == 1 ? '完成' : '撤单'
                        state.progressType = result.planType == 0 ? ' 多期投注套餐' : '自动多期投注'
                    }
                    const ticketList = result.ticketList
                    ticketList.forEach(ele => initTermlyNumber(ele))
                    state.lotteryList.push(...ticketList)
                    state.isLoadAll = state.lotteryList.length == result.count
                    state.page++
                }
            } catch(e) {
                console.error(e)
            }
        }
        if (isTermly) {
            termlyBonusDetail()
        } else {
            bonusList()
        }
        function getLotteryStatus(lotteryState, bonusState, isWin, winMoney) {
            return lotteryState == 0 ? '等待付款'
                  :lotteryState == 1 ? '已退单'
                  :lotteryState == 2 ? '出票失败'
                  :lotteryState == 3 ? '出票中'
                  :bonusState == 0 ? '等待开奖'
                  :isWin ?   `已中奖(${appendCent(winMoney/100)}元)` : '未中奖'
        }
        return {
            ...toRefs(state),
            lotteryKind,
            formatTime,
            downEvent,
            heigthChange,
            isWin,
            issueName,
            showPullIcon,
            getColor,
            isTermly,
            termlyBonusDetail,
            appendCent,
            getLotteryStatus
        }
    },
}
</script>
<style lang='scss' scoped>
:deep .van-collapse-item__content{
    background: $bg-gray;
    margin: 0 $margin-big;
    padding: 15px 0px 10px 8px;
    border-radius: $box-radius-small;
}
.green {
    color: #15a962;
}
.detail {
    padding: $margin-big;
    .load_more {
        text-align: center;
        color: $gray-light;
        font-size: $font-small;
        line-height: 3em;
    }
    .ani {
        transition: all 0.3s;
        overflow: hidden;
        max-height: 1000px;
        // width: 83%;
    }
    &_head {
        background: $bg-gray;
        border-radius: $box-radius-small;
        .lottery_info {
            display: flex;
            padding: $margin-big;
            justify-content: space-between;
            align-items: center;
            &_left {
                display: flex;
                align-items: center;
                // font-size: $font-small;
                img {
                    width: 50px;
                    padding-right: 5px;
                }
                p {
                    &:first-child {
                        font-size: $font-big;
                        span {
                            display: inline-block;
                            margin-left: 8px;
                            font-size: $font-middle;
                        }
                    }
                    &:last-child {
                        // font-size: $font-less;
                        padding-top: 2px;
                    }
                }
            }
        }
        .lottery_money {
            border-top: solid $line-gray 1px;
            display: flex;
            justify-content: space-between;
            text-align: center;
            padding: 15px 0;
            &_item {
                width: 50%;
                &:last-child {
                    border-left: solid $line-gray 1px;
                }
                &_label {
                    font-size: $font-small;
                    color: $gray-light;
                    padding-bottom: 5px;
                }
                &_num {
                    font-weight: 700;
                    font-size: $font-small;
                    span {
                        font-size: $font-max;
                    }
                }
            }
        }
    }
    .padding_bottom_25 {
        padding-bottom: 25px;
    }
    &_info {
        background: $bg-gray;
        padding: $margin-big $margin-big $margin-big $margin-big;
        border-radius: $box-radius-small;
        color: $gray-middle;
        font-size: $font-small;
        line-height: 1.8em;
        .tip_text {
            font-size: $font-less;
            padding: 5px 0;
        }
    }
    &_info_termly {
        padding: 10px 0;
        p {
            line-height: 1.6em;
        }
    }
    &_title {
        font-weight: 700;
        padding: 15px 0 8px 0;
    }
    .bet_number {
        display: flex;
        word-break: keep-all;
        position: relative;
        flex-wrap: wrap;
        &_ball {
            display: flex;
            flex-wrap: wrap;
            padding: 3px 0;
            padding-left: 65px;
        }
        :deep .ball {
            width: 30px;
            height: 30px;
            line-height: 30px;
            margin-left: 3px;
            font-size: $font-big;
        }
        &_title {
            padding-top: 9px;
            color: #000;
            position: absolute;
        }
        &_pull {
            position: absolute;
            bottom: -22px;
            height: 15px;
            left: 0;
            right: 0;
            &::after{
                content: '';
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                width: 19px;
                height: 19px;
                background: url('../../assets/imgs/mine/mine_pull_icon.png') center center no-repeat;
                background-size: contain;
            }
            
        }
    }
}
</style>